import { lazy } from 'react';

import { RouteDefinition } from 'components/RenderRoutes';
import { DOCUMENT_PREVIEW_PATH, MESSAGE_SENDING_PATH } from 'routeConstants';
import {
  HEATING_COST_ENERGY_CREATE_PATH,
  HEATING_COST_ENERGY_EDIT_PATH,
  HEATING_COST_ENERGY_EDITOR_PAGE,
  HEATING_COST_OTHER_CREATE_PATH,
  HEATING_COST_OTHER_EDIT_PATH,
  HEATING_COST_OTHER_EDITOR_PAGE,
} from 'pages/HeatingCosts/routesHeatingCosts';
import {
  PDF_DOCUMENT_ASSIGNER_COMPONENT,
  PDF_DOCUMENT_ASSIGNER_PATH,
} from 'pages/PdfPageAssigner/routes';

import { HEATING_CENTER_EDIT_PATH, HEATING_CENTER_EDITOR_PAGE } from 'pages/HeatingCenter/routesHeatingCenter';
import { WkaList } from './list/WkaList';

const WkaEditor = lazy(() => import('./WkaEditor/WkaEditor'));
const MessageSendingPageWithProvider = lazy(() => import('pages/MessageSendingPage/MessageSendingPage'));
const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

export const WKA_EDITOR_COMPONENT = WkaEditor;
export const WKA_CREATE_PATH = '/wka/create';
export const WKA_EDIT_PATH = '/wka/edit/:wkaId';

export const getWkaEditingRoute = (wkaId: number | string) => WKA_EDIT_PATH.replace(':wkaId', wkaId?.toString());
export const getWkaSendingRoute = (wkaId: number) => `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}`.replace(':wkaId', wkaId?.toString());

export const getHeatingCostEnergyEditPath = (wkaId: string | number, heatingCostGroupId: string | number, hceId: string | number) => `${getWkaEditingRoute(wkaId)}${HEATING_COST_ENERGY_EDIT_PATH.replace(':heatingCostGroupId', heatingCostGroupId?.toString()).replace(':hceId', hceId?.toString())}`;
export const getHeatingCostEnergyCreatePath = (wkaId: number, heatingCostGroupId: string) => `${getWkaEditingRoute(wkaId)}${HEATING_COST_ENERGY_CREATE_PATH.replace(':heatingCostGroupId', heatingCostGroupId)}`;

export const getHeatingCostOtherEditPath = (wkaId: string | number, heatingCostGroupId: string | number, hcoId: string | number) => `${getWkaEditingRoute(wkaId)}${HEATING_COST_OTHER_EDIT_PATH.replace(':heatingCostGroupId', heatingCostGroupId?.toString()).replace(':hcoId', hcoId?.toString())}`;
export const getHeatingCostOtherCreatePath = (wkaId: number, heatingCostGroupId: string) => `${getWkaEditingRoute(wkaId)}${HEATING_COST_OTHER_CREATE_PATH.replace(':heatingCostGroupId', heatingCostGroupId)}`;

export const getHeatingCenterEditPathWka = (wkaId: string | number, heatingCenterId: string | number) => `${getWkaEditingRoute(wkaId)}${HEATING_CENTER_EDIT_PATH.replace(':hcId', heatingCenterId?.toString())}`;

export const WKA_PATH = '/wka';

export const WkaRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/wka',
      component: WkaList,
      subRoutes: [
        {
          path: WKA_CREATE_PATH,
          component: WKA_EDITOR_COMPONENT,
          type: 'focus',
        },
        {
          path: WKA_EDIT_PATH,
          component: WKA_EDITOR_COMPONENT,
          type: 'focus',
          subRoutes: [
            {
              path: `${WKA_EDIT_PATH}/${PDF_DOCUMENT_ASSIGNER_PATH}`,
              component: PDF_DOCUMENT_ASSIGNER_COMPONENT,
              type: 'overlay',
            },
            {
              path: `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}`,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${WKA_EDIT_PATH}${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },
            {
              path: [`${WKA_EDIT_PATH}${HEATING_COST_ENERGY_CREATE_PATH}`, `${WKA_EDIT_PATH}${HEATING_COST_ENERGY_EDIT_PATH}`],
              component: HEATING_COST_ENERGY_EDITOR_PAGE,
              type: 'overlay',
            },
            {
              path: [`${WKA_EDIT_PATH}${HEATING_COST_OTHER_CREATE_PATH}`, `${WKA_EDIT_PATH}${HEATING_COST_OTHER_EDIT_PATH}`],
              component: HEATING_COST_OTHER_EDITOR_PAGE,
              type: 'overlay',
            },
            {
              path: `${WKA_EDIT_PATH}${HEATING_CENTER_EDIT_PATH}`,
              component: HEATING_CENTER_EDITOR_PAGE,
              type: 'overlay',
            },
          ],
        },
      ],
    },
  ],
};
