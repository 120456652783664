import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import _ from 'lodash';
import moment from 'moment';
import {
  ExtendedPropertyDto,
} from 'pages/Property/PropertyEditing/services/PropertyEditingContext/PropertyEditingContext';
import PropTypes from 'prop-types';

import { PropertyDisplayDto, PropertyLegacyControllerApi, PropertyLegacyDtoAdministrationTypeEnum } from '../api/accounting';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA, { DefaultDataInterface } from '../lib/data';
import { showNotification } from '../lib/Notification';
import { LanguageContext } from './LanguageContext';
import { AuthContext } from './AuthContext';

const PAGE_SIZE = 30;


export interface PropertyListContextInterface {
  selectedProperty?: DefaultDataInterface<any>
}

export const PropertyListContext: any = React.createContext<PropertyListContextInterface>({});

export default function PropertyListProvider({ children }: any): JSX.Element {
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const [propertySort, setPropertySort] = useState({
    field: 'propertyState',
    order: -1,
  });
  const propertySortRef: any = useRef();
  propertySortRef.current = propertySort;

  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const defaultContext = {
    propertyList: [],
    propertySmartSearch: [],
    page: 0,
    lastPage: false,
    property: {},
  };

  const defaultPropertyFilterState = useMemo(() => ({
    administrationTypes: [PropertyLegacyDtoAdministrationTypeEnum.WEG, PropertyLegacyDtoAdministrationTypeEnum.MV],
  }), []);

  const [propertyListState, setPropertyListState] = useState(DEFAULT_DATA<any>(defaultContext));
  const [propertyFilterState, setPropertyFilterState] = useState<any>({});
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(DEFAULT_DATA<any>({}));
  const [selectedDisplayProperty, setSelectedDisplayProperty] = useState(DEFAULT_DATA<any>({}));
  const [selectedDisplayPropertyList, setSelectedDisplayPropertyList] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]));
  const [selectedDisplayPropertyId, setSelectedDisplayPropertyId] = useState(0);
  const [selectedDisplayPropertyIdList, setSelectedDisplayPropertyIdList] = useState([]);
  const [selectedPropertyHrId, setSelectedPropertyHrId] = useState();
  const lastRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (selectedPropertyHrId) {
      getPropertyByHrId(selectedPropertyHrId);
    }
  }, [selectedPropertyHrId]);

  useEffect(() => {
    if (selectedDisplayPropertyId) {
      onLoadPropertyDisplayById(selectedDisplayPropertyId);
    }
  }, [selectedDisplayPropertyId]);

  useEffect(() => {
    if (selectedProperty && selectedProperty.data) {
      setSelectedDisplayPropertyId(selectedProperty.data.id);
    }
  }, [selectedProperty]);

  useEffect(() => {
    try {
      selectedDisplayPropertyIdList.forEach((selectedId: number) => {
        if (selectedDisplayPropertyList.data!.filter(property => property.id === selectedId).length <= 0) {
          onLoadPropertyDisplayByIdAndAddItToList(selectedId);
        }
      });
      // remove properties which were removed from the id list
      selectedDisplayPropertyList.data!.forEach((propertyDisplay: PropertyDisplayDto) => {
        if (selectedDisplayPropertyIdList.filter(id => id === propertyDisplay.id).length <= 0) {
          setSelectedDisplayPropertyList((propertyList) => {
            const newList = _.cloneDeep(propertyList.data) || [];
            const index = newList.indexOf(propertyDisplay);
            newList.splice(index, 1);
            return propertyList.load(newList);
          });
        }
      });
    } catch (e) {
      // NOOP
    }
  },
    [JSON.stringify(selectedDisplayPropertyIdList)]);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadPropertyList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [propertyFilterState]);

  const getPropertyByHrId = async (propertyHrId: string | null): Promise<void> => {
    if (!propertyHrId) {
      return;
    }
    setSelectedProperty(selectedProperty.startLoading());
    propertyControllerApi.getPropertyUsingGET({ propertyHrId })
      .then((prp) => {
        setSelectedProperty(selectedProperty.load(prp));
      })
      .catch(() => {
        setSelectedProperty(selectedProperty.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyListContext.loadError.message),
          description: tl(translations.notifications.propertyListContext.loadError.description),
          type: 'warning',
        });
      });
  };

  const updateFilterState = (data: object) => {
    setPropertyFilterState((currentState: any) => ({
      ...currentState,
      ...data,
    }));
  };

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadPropertyList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [propertySort]);


  const updateListState = (data: any) => {
    setPropertyListState(propertyListState.load({
      ...propertyListState.data,
      ...data,
    }));
  };


  const setSortField = (field: string) => {
    const order = propertySortRef.current.field === field ? propertySortRef.current.order * (-1) : 1;
    setPropertySort({
      field,
      order,
    });
  };

  const onLoadPropertyList = (resetPage: boolean = false, sort: any = propertySort): void => {
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setPropertyListState(propertyListState.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/filter`,
      {
        ...(propertyFilterState ?? {}),
        excludeFields: ['bankAccounts'],
        page: resetPage ? 0 : propertyListState.data.page,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      })
      .then((response: any) => {
        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        updateListState({
          propertyList: resetPage ? response.content : propertyListState.data.propertyList.concat(response.content),
          page: resetPage ? 1 : propertyListState.data.page + 1,
          lastPage: response.last,
        });
      })
      .catch(() => {
        setPropertyListState({
          ...propertyListState.failed(),
          data: {
            ...propertyListState.data,
            lastPage: true,
          },
        });
        showNotification({
          key: 'loadPropertyListError',
          message: tl(translations.notifications.propertyListContext.loadError.message),
          description: tl(translations.notifications.propertyListContext.loadError.description),
          type: 'warning',
        });
      });
  };

  const onDeleteProperty = (propertyId: number): void => {
    setPropertyListState(propertyListState.startLoading());
    backend.delete(`${endpointUrls.PROPERTY}/${propertyId}`, {})
      .then(() => {
        const tempProperties = propertyListState.data.propertyList.filter((property: any) => property.id !== propertyId);
        updateListState({
          propertyList: tempProperties,
        });
        showNotification({
          key: 'deletePropertySuccess',
          message: tl(translations.notifications.propertyListContext.deleteSuccess.message),
          type: 'success',
        });
      })
      .catch((err) => {
        setPropertyListState(oldState => oldState.finishLoading());
        if (err.title === 'Property with contracts cannot be deleted.') {
          showNotification({
            key: 'deletePropertyWithContractsError',
            message: tl(translations.notifications.propertyListContext.deletePropertyWithContractError),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'deletePropertyError',
            message: tl(translations.notifications.propertyListContext.deleteError.message),
            type: 'error',
          });
        }
      });
  };

  const onUpdatePropertyInList = (propertyData: ExtendedPropertyDto): void => {
    const updatedPropertyIndex = propertyListState.data.propertyList.findIndex(property => property.id === propertyData.id);
    const tempProperties = [...propertyListState.data.propertyList];
    if (updatedPropertyIndex === -1) {
      tempProperties.unshift(propertyData);
    } else {
      tempProperties[updatedPropertyIndex] = {
        ...tempProperties[updatedPropertyIndex], // keep the existing fields
        ...propertyData,
      };
    }
    updateListState({
      propertyList: tempProperties,
    });
  };

  const optionObjectFromProperty = ((property: any, valueKey?: string) => ({
    label: property.name,
    value: valueKey ? property[valueKey] : property.propertyHrId,
    name: property.name,
    propertyIdInternal: property.propertyIdInternal,
  }));

  const onLoadProperty = (propertyHrId: string) => {
    // TODO: remove the usage of this function, use the setSelectedProperty instead
    //  (there is a hook to load the selected property without changing the propertyList)
    if (!propertyHrId) {
      return;
    }

    setPropertyListState(propertyListState.startLoading());
    const p = backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}`, {});
    p.then((response: any) => {
      const { propertySmartSearch } = propertyListState.data;
      const propertyOption = optionObjectFromProperty(response);
      if (propertySmartSearch.filter((option: any) => option.value === propertyOption.value).length === 0) {
        propertySmartSearch.push(propertyOption);
      }
      updateListState({
        propertySmartSearch,
        propertyList: [response],
        property: response,
      });
    })
      .catch(() => {
        setPropertyListState(propertyListState.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
    return p;
  };

  const onLoadPropertyDisplayById = (propertyId: number) => {
    if (!propertyId) return;
    setSelectedDisplayProperty(selectedDisplayProperty.startLoading());
    const p = backend.get(`${endpointUrls.PROPERTY}/display`, { propertyId });
    p.then((response: any) => {
      setSelectedDisplayProperty(selectedDisplayProperty.load(response));
    })
      .catch(() => {
        setPropertyListState(propertyListState.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const addPropertyDisplayToList = (propertyDisplay: PropertyDisplayDto) => {
    setSelectedDisplayPropertyList((list: DefaultDataInterface<PropertyDisplayDto[]>) => {
      const newList = _.cloneDeep(list.data) || [];
      newList.push(propertyDisplay);
      return list.load(newList);
    });
  };
  const onLoadPropertyDisplayByIdAndAddItToList = (propertyId: number) => {
    if (!propertyId) return;
    setSelectedDisplayPropertyList(prev => prev.startLoading());
    const p = backend.get(`${endpointUrls.PROPERTY}/display`, { propertyId });
    p.then((response: any) => {
      addPropertyDisplayToList(response);
    })
      .catch(() => {
        setSelectedDisplayPropertyList(selectedDisplayPropertyList.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onClearPropertyList = (): void => {
    updateListState({
      propertyList: [],
      page: 0,
      lastPage: false,
    });
  };

  const onClearFilterState = (): void => {
    setPropertyFilterState({});
  };

  const onResetListState = () => {
    onClearPropertyList();
    onClearFilterState();
    setInitialFilterUpdate(true);
  };

  const removeDeletedPropertyFromList = (propertyId: number) => {
    const tempProperties = propertyListState.data.propertyList.filter((property: any) => property.id !== propertyId);
    updateListState({
      propertyList: tempProperties,
    });
  };

  const currentEconomicYear = useMemo(() => {
    try {
      const currentYear = moment().year();
      const tempEconomicYearStart = new Date(selectedProperty.data.economicYearStart);
      const tempEconomicYearEnd = new Date();
      tempEconomicYearStart.setFullYear(currentYear);
      return [tempEconomicYearStart, tempEconomicYearEnd];
    } catch (e) {
      return [null, null];
    }
  }, [selectedProperty]);

  const previousEconomicYear = useMemo(() => {
    try {
      const currentYear = moment().year() - 1;
      const tempEconomicYearStart = new Date(selectedProperty.data.economicYearStart);
      const tempEconomicYearEnd = new Date(selectedProperty.data.economicYearEnd);
      tempEconomicYearStart.setFullYear(currentYear);
      tempEconomicYearEnd.setFullYear(currentYear);
      if (tempEconomicYearEnd < tempEconomicYearStart) {
        tempEconomicYearEnd.setFullYear(currentYear + 1);
      }
      return [tempEconomicYearStart, tempEconomicYearEnd];
    } catch (e) {
      return [null, null];
    }
  }, [selectedProperty]);


  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setPropertyFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  return (
    <PropertyListContext.Provider value={{
      ...propertyListState,
      selectedProperty,
      setSelectedProperty,
      setSelectedPropertyHrId,
      onSetDefaultFilterFromQueryParams,
      selectedPropertyHrId,
      selectedDisplayPropertyId,
      setSelectedDisplayPropertyId,
      selectedDisplayPropertyIdList,
      setSelectedDisplayPropertyIdList,
      selectedDisplayProperty,
      selectedDisplayPropertyList,
      propertyFilterState,
      defaultPropertyFilterState,
      propertySort,
      setPropertyFilterState,
      updateFilterState,
      onLoadPropertyList,
      onLoadProperty,
      onUpdatePropertyInList,
      setSortField,
      onClearPropertyList,
      onClearFilterState,
      onResetListState,
      onDeleteProperty,
      onLoadPropertyDisplayById,
      removeDeletedPropertyFromList,
      sortField: propertySortRef.current.field,
      sortOrder: propertySortRef.current.order,
      currentEconomicYear,
      previousEconomicYear,
    }}
    >
      {children}
    </PropertyListContext.Provider>
  );
}


PropertyListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
