import { RouteDefinition } from 'components/RenderRoutes';
import { MESSAGE_SENDING_PATH, DOCUMENT_PREVIEW_PATH } from 'routeConstants';
import MessageSendingPageWithProvider from 'pages/MessageSendingPage/MessageSendingPage';
import DocumentPreviewPage from 'pages/DocumentPreview/DocumentPreviewPage';
import { SerialLetterEditorWithProvider } from './pages/SerialLetterEditor/SerialLetterEditor';
import SerialLetterList from './pages/SerialLetterList/SerialLetterList';

export const SERIAL_LETTER_SENDING_PATH = `/letters/edit/:letterId${MESSAGE_SENDING_PATH}`;

export const getSendRouteForSerialLetter = (letterId: number) => SERIAL_LETTER_SENDING_PATH.replace(':letterId', letterId?.toString());

export const SerialLetterRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/letters',
      component: SerialLetterList,
      subRoutes: [
        {
          path: '/letters/create',
          type: 'overlay',
          component: SerialLetterEditorWithProvider,
        },
        {
          path: '/letters/edit/:letterId',
          type: 'overlay',
          component: SerialLetterEditorWithProvider,
          subRoutes: [{
            path: SERIAL_LETTER_SENDING_PATH,
            component: MessageSendingPageWithProvider,
            type: 'overlay',
            subRoutes: [
              {
                path: `${SERIAL_LETTER_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                component: DocumentPreviewPage,
                type: 'overlay',
              },
            ],
          }],
        },
        {
          path: '/letters/view/:letterId',
          type: 'overlay',
          component: SerialLetterEditorWithProvider,
        },
      ],
    },
  ],
};
