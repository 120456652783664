import { lazy } from 'react';
import { DOCUMENT_PREVIEW_PATH, MESSAGE_SENDING_PATH } from 'routeConstants';
import HouseMoneySettlement from './pages/HouseMoenySettlementList/HouseMoneySettlement';
import HouseMoneySettlementOverview from './pages/HouseMoneySettlementOverview/pages/HouseMoneySettlementOverview/HouseMoneySettlementOverview';
import { RouteDefinition } from '../../components/RenderRoutes';
import HouseMoneySettlementCloseYearCreation from './pages/HouseMoneySettlementCloseYear/HouseMoneySettlementCloseYearCreation';

const MessageSendingPageWithProvider = lazy(() => import('pages/MessageSendingPage/MessageSendingPage'));
const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

const houseMoneySettlementOverviewPath = '/house-money-settlement/:propertyHrId';

export const getHouseMoneySettlementOverviewRoute = ({ propertyHrId }: { propertyHrId: string }) => (
  houseMoneySettlementOverviewPath.replace(':propertyHrId', propertyHrId)
);

export const getHouseMoneySettlementSendingRoute = ({ propertyHrId }: { propertyHrId: string }) => (
  `${houseMoneySettlementOverviewPath}${MESSAGE_SENDING_PATH}`.replace(':propertyHrId', propertyHrId)
);


export const HouseMoneySettlementRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/house-money-settlement',
      component: HouseMoneySettlement,
      subRoutes: [
        {
          path: houseMoneySettlementOverviewPath,
          component: HouseMoneySettlementOverview,
          type: 'focus',
          subRoutes: [
            {
              path: `${houseMoneySettlementOverviewPath}${MESSAGE_SENDING_PATH}`,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${houseMoneySettlementOverviewPath}${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },
            {
              path: '/house-money-settlement/:hmsId/finalize',
              component: HouseMoneySettlementCloseYearCreation,
              type: 'focus',
            },
          ],
        },
      ],
    },
    {
      path: '*/:propertyHrId/house-money-settlement',
      component: HouseMoneySettlementOverview,
      type: 'overlay',
      width: '90%',
    },
  ],
};
