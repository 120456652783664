import { RouteDefinition } from 'components/RenderRoutes';
import { lazy } from 'react';
import { MESSAGE_SENDING_PATH, DOCUMENT_PREVIEW_PATH } from 'routeConstants';
import MessageSendingPageWithProvider from './MessageSendingPage';

const DocumentPreviewPage = lazy(() => import('pages/DocumentPreview/DocumentPreviewPage'));

export const MessageSendingRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: MESSAGE_SENDING_PATH,
      component: MessageSendingPageWithProvider,
      subRoutes: [
        {
          path: `${MESSAGE_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
          component: DocumentPreviewPage,
          type: 'overlay',
        },
      ],
    },
  ],
};
