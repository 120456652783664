import DocumentPreviewPage from 'pages/DocumentPreview/DocumentPreviewPage';
import MessageSendingPageWithProvider from 'pages/MessageSendingPage/MessageSendingPage';
import { MESSAGE_SENDING_PATH, DOCUMENT_PREVIEW_PATH } from 'routeConstants';
import { getWkaEditingRoute, WKA_EDIT_PATH, WKA_EDITOR_COMPONENT } from 'pages/Wka/routes';
import { PDF_DOCUMENT_ASSIGNER_COMPONENT, PDF_DOCUMENT_ASSIGNER_PATH } from 'pages/PdfPageAssigner/routes';
import { RouteDefinition } from '../../components/RenderRoutes';
import OwnersMeetingsInvitationList from './list/OwnersMeetingsInvitationList';
import OwnersMeetingInvitationEditing from './OwnersMeetingInvitationEditing/OwnersMeetingInvitationEditing';
import AgendaItemDetails from './OwnersMeetingInvitationEditing/sections/AgendaSection/components/AgendaItemDetails';

export const OWNERS_MEETING_SENDING_PATH = `/owners-meeting/invitation/edit/:etvId${MESSAGE_SENDING_PATH}`;

export const OWNERS_MEETING_EDIT_PATH = '/owners-meeting/invitation/edit/:etvId';
export const getSendRouteForOwnersMeetingInvitation = (etvId: number) => OWNERS_MEETING_SENDING_PATH.replace(':etvId', etvId?.toString());
export const getWkaEditingRouteForOMI = (locationPathname: string, propertyHrId: string, wkaId: number) => `${locationPathname}/${propertyHrId}${getWkaEditingRoute(wkaId)}`;
export const OwnersMeetingInvitationRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/owners-meeting/invitation',
      component: OwnersMeetingsInvitationList,
      subRoutes: [
        {
          path: '/owners-meeting/invitation/create',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
        },
        {
          path: '/owners-meeting/invitation/edit/:etvId',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
          subRoutes: [
            {
              path: ['/owners-meeting/invitation/edit/:etvId/agenda-item/:agendaItemId', '/owners-meeting/invitation/edit/:etvId/agenda-item/create'],
              component: AgendaItemDetails,
              type: 'overlay',
            },
            {
              path: OWNERS_MEETING_SENDING_PATH,
              component: MessageSendingPageWithProvider,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${OWNERS_MEETING_SENDING_PATH}${DOCUMENT_PREVIEW_PATH}`,
                  component: DocumentPreviewPage,
                  type: 'overlay',
                },
              ],
            },

            {
              path: [
                `${OWNERS_MEETING_EDIT_PATH}/:propertyHrId${WKA_EDIT_PATH}`,
              ],
              component: WKA_EDITOR_COMPONENT,
              type: 'overlay',
              subRoutes: [
                {
                  path: `${OWNERS_MEETING_EDIT_PATH}/:propertyHrId${WKA_EDIT_PATH}/${PDF_DOCUMENT_ASSIGNER_PATH}`,
                  component: PDF_DOCUMENT_ASSIGNER_COMPONENT,
                  type: 'overlay',
                },
              ],
            },
          ],
        },
        {
          path: '/owners-meeting/invitation/view/:etvId',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
          subRoutes: [
            {
              path: '/owners-meeting/invitation/view/:etvId/agenda-item/:agendaItemId',
              component: AgendaItemDetails,
              type: 'overlay',
            },
          ],
        },
      ],
    },
    {
      path: '*/:propertyHrId/owners-meeting/invitation',
      component: OwnersMeetingsInvitationList,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/owners-meeting/invitation/create',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/owners-meeting/invitation/edit/:etvId',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
        },
        {
          path: '*/:propertyHrId/owners-meeting/invitation/view/:etvId',
          component: OwnersMeetingInvitationEditing,
          type: 'focus',
        },
      ],
    },
  ],
};
